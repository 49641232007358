import "./UserDetails.scss";
import React, { useState } from "react";
import { Section } from "../Section/Section";
import { useFormContext, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { Input } from "../Input/Input";

export const UserDetails = () => {
  const {
    control,
    register,
    formState: { errors },
    resetField,
  } = useFormContext();
  const [personalData, setPersonalData] = useState(true);
  const [companyData, setCompanyData] = useState(false);

  const handlePersonalData = () => {
    setPersonalData(true);
    setCompanyData(false);
    resetField("nip");
    resetField("nazwafirmy");
  };

  const handleCompanyData = () => {
    setPersonalData(false);
    setCompanyData(true);
  };

  const required = {
    value: true,
    message: "Pole obowiązkowe",
  };

  return (
    <Section
      title={
        <>
          <img src="/img/person_icon.svg" alt="person icon" />
          <h2 id="UserDetails">Zgłaszający</h2>
        </>
      }
      description={
        <div>
                 Wprowadź swoje dane, <span className='bold'>adres odbioru</span> oraz numer kontaktowy. Jeśli zgłaszasz naprawę w imieniu firmy, wszystkie dokumenty związane z naprawą zostaną wystawione na wskazany podmiot.

        </div>
    }
    >
      <div>
        <div className="UserDetails__form--title">
          <button
            type="button"
            className={
              personalData
                ? " UserDetails__form--title--button UserDetails__form--title--clicked"
                : "UserDetails__form--title--button UserDetails__form--title--option"
            }
            onClick={handlePersonalData}
          >
            <p className="UserDetails__form--title--text">Osoba prywatna</p>
          </button>
          <button
            type="button"
            className={
              companyData
                ? "UserDetails__form--title--button UserDetails__form--title--clicked"
                : "UserDetails__form--title--button UserDetails__form--title--option"
            }
            onClick={handleCompanyData}
          >
            <p className="UserDetails__form--title--button--text">Firma</p>
          </button>
        </div>
        <div className="UserDetails__form__data--line">
          <Input
            inputName="imie"
            className="long"
            placeholder="Imię"
            type="text"
            error={errors?.imie}
            validationSchema={{
              required: required,
              maxLength: { value: 35, message: "Maksymalnie 35 znaków" },
            }}
          />

          <Input
            inputName="nazwisko"
            className="long"
            placeholder="Nazwisko"
            type="text"
            error={errors?.nazwisko}
            validationSchema={{
              required: required,
              maxLength: { value: 35, message: "Maksymalnie 35 znaków" },
            }}
          />

        </div>
        {personalData ? (
          <></>
        ) : (
          <div className="UserDetails__form__data--line">
            <Input
              inputName="nazwafirmy"
              className="long"
              placeholder="Nazwa firmy"
              type="text"
              error={errors?.nazwafirmy}
              validationSchema={{
                required: required,
                maxLength: { value: 46, message: "Maksymalnie 46 znaków" },
              }}
            />

            <label className="label">
              <InputMask
                mask="999-999-99-99"
                maskChar=""
                {...register("nip", {
                  required: { value: true, message: "Pole obowiązkowe" },
                  minLength: {
                    value: 13,
                    message: "Wpisz 10-cyfrowy nr NIP",
                  },
                })}
                className="long"
                placeholder="NIP"
                name="nip"
              ></InputMask>
              {errors.nip?.message && (
                <p className="error">{errors.nip?.message}</p>
              )}
            </label>
          </div>
        )}

        <div className="UserDetails__form__data--line">
          <Input
            inputName="nadawcaulica"
            className="long"
            placeholder="Ulica"
            type="text"
            error={errors?.nadawcaulica}
            validationSchema={{
              required: required,
              maxLength: { value: 50, message: "Maksymalnie 50 znaków" },
              onChange: (e) => {
                resetField("godzina_odbioru");
                resetField("dzien_odbioru");
              },
            }}
          />

          <div className="houseNumber">
          <Input
            inputName="nadawcanrdomu"
            className="short"
            placeholder="Nr domu"
            type="text"
            error={errors?.nadawcanrdomu}
            validationSchema={{
              required: required,
              maxLength: { value: 8, message: "Max 8 znaków" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
              },
            }}
          />
           <Input
            inputName="nadawcanrlokalu"
            className="short"
            placeholder="Nr lokalu"
            type="text"
            error={errors?.nadawcanrlokalu}
            validationSchema={{
              // required: required,
              maxLength: { value: 5, message: "Max 5 znaków" },
                  onChange: (e) => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
              },
            }}
          />
          </div>

        </div>
        <div className="UserDetails__form__data--line">
          <label className="label">
            <InputMask
              mask="99-999"
              maskChar=""
              {...register("nadawcakodpocztowy", {
                required: { value: true, message: "Pole obowiązkowe" },
                minLength: { value: 6, message: "Wpisz 5 cyfr" },
                onChange: (e) => {
                  resetField("godzina_odbioru");
                  resetField("dzien_odbioru");
                },
              })}
              className={window.innerWidth < 768 ? "longZipCode" : "short"}
              placeholder="Kod pocztowy"
              name="nadawcakodpocztowy"
            ></InputMask>
            {errors.nadawcakodpocztowy?.message && (
              <p className="error">{errors.nadawcakodpocztowy.message}</p>
            )}
          </label>
          <label className="label">
            <input
              {...register("nadawcamiasto", {
                required: { value: true, message: "Pole obowiązkowe" },
                maxLength: { value: 35, message: "Maksymalna długość 35 cyfr" },
                onChange: (e) => {
                  resetField("godzina_odbioru");
                  resetField("dzien_odbioru");
                },
              })}
              className="verylong"
              type="text"
              placeholder="Miejscowość"
              name="nadawcamiasto"
            />

            {errors.nadawcamiasto?.message && (
              <p className="error">{errors.nadawcamiasto.message}</p>
            )}
          </label>
        </div>
        <div className="UserDetails__form__data--line">
          <label className="label">
            <InputMask
              mask="999-999-999"
              maskChar=""
              {...register("tel", {
                required: { value: true, message: "Pole obowiązkowe" },
                minLength: { value: 11, message: "Wpisz 9 cyfr" },
                // pattern: /^([0-9_ _-]{11,12}|[0-9]{9})$/,
              })}
              className="long"
              type="tel"
              placeholder="Telefon"
              name="tel"
            ></InputMask>
            {errors.tel?.message && (
              <p className="error">{errors.tel.message}</p>
            )}
          </label>
          <label className="label">
            <input
              {...register("email", {
                required: { value: true, message: "Pole obowiązkowe" },
                maxLength: { value: 128, message: "Maksymalnie 128 znaków" },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Podaj poprawny e-mail",
                },
              })}
              className="long"
              type="email"
              placeholder="E-mail"
              name="email"
            />
            {errors.email?.message && (
              <p className="error">{errors.email.message}</p>
            )}
          </label>
        </div>
      </div>
    </Section>
  );
};
