import './Form.scss';
import React from 'react';
import { isEmpty } from 'lodash';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { useState, useEffect } from 'react';
import {
  UserDetails,
  ComplaintType,
  ToolDetails,
  PackageDetails,
  
} from './components';
import { Modal } from '../Modal';




export const Form = () => {
  const [uploadedFile, setUploadedFile] = useState('');
  // const [formData, setFormData] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [uploadedCertification, setUploadedCertification] = useState('')

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      imie: '',
      nazwisko: '',
      nazwafirmy: '',
      nip: '',
      email: '',
      tel: '',
      narzedzia: [
        {
          typzgloszenia: '',
          akceptkosztownogw: '',
          modeltowaru: '',
          nrseryjny: '',
          datazakupu: '',
          opisusterki: '',
          skandowodu: '',
          skancertyfikatu: '',
          maksymalnykoszt: '',
        },
      ],
      typprzesylki: '',
      nadawcaulica: '',
      nadawcanrdomu: '',
      nadawcanrlokalu: '',
      nadawcakodpocztowy: '',
      nadawcamiasto: '',
      dzien_odbioru: '',
      godzina_odbioru: '',
      idpaczkomatu: '',
      rozmiar_paczki: '',
    },
  });

  const {
    control,
    handleSubmit,
    watch,
    register,
    setError,
    clearErrors,
    formState: { errors, isValid },
    resetField
  } = methods;


  const onSubmit = (data) => {
    setModalIsOpen(true);
  };



  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UserDetails />
        <ComplaintType />
        <ToolDetails />
        <PackageDetails />

        <div className="confirmation">
          <button
            className={isValid ? "confirmation__buttonUnlocked confirmationButton" : "confirmation__buttonLocked confirmationButton"}
            type="submit"
            id="Confirmation"
           
          >
            Zgłoś naprawę
            
          </button>
          <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)} />
        </div>
      </form>
    </FormProvider>
  );
};
