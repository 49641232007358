import "./Modal.scss";
import ReactDom from "react-dom";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ModalThankYou } from "../ModalThankYou";
import { ModalError } from "../ModalError";
import { Spinner } from "../Spinner";
import {db} from "../settings";

export const Modal = ({ open, onClose }) => {
  const [ModalThankYouIsOpen, setModalThankYouIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [uploadedFile, setUploadedFile] = useState("");
  const [uploadedCertification, setUploadedCertification] = useState("");

  const {
    getValues,
    formState: { errors },
    handleSubmit,
    watch,
  } = useFormContext();
  const values = getValues();

  const { ...file } = watch("narzedzia[0].skandowodu");
  const { ...certificationFile } = watch("narzedzia[0].skancertyfikatu");

  async function fetchForm(data) {
  
    setIsLoading(true);
    setIsError(false);
    console.log("token", db.token, "url", db.url, );
    try {
      const response = await fetch(`${db.url}/api/new_form`, {
        method: "post",
        headers: {
          Authorization: db.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not OK");
      }
      const dataForm = await response.json();

    console.log("Success!!!!!!!!", dataForm);
        
        setIsLoading(false);
        setModalThankYouIsOpen(true);

    } catch (error) {
      console.log("ERRRRROR", error);
      setIsLoading(false);
      setIsError(true);
    }
  }

  useEffect(() => {
    const reader = new FileReader();

    if (file && file?.[0]?.type) {
      reader.readAsDataURL(file[0]);

      reader.onload = function (e) {
        setUploadedFile(reader.result);
      };
    }
  }, [file]);

  useEffect(() => {
    const certificationReader = new FileReader();

    if (certificationFile && certificationFile?.[0]?.type) {
      certificationReader.readAsDataURL(certificationFile[0]);

      certificationReader.onload = function (e) {
        setUploadedCertification(certificationReader.result);
      };
    }
  }, [certificationFile]);

  const onSubmit = (data) => {
    console.log(JSON.stringify(data));
    const submittedData = {
      ...data,
      narzedzia: [
        {
          ...data.narzedzia[0],
          skandowodu: uploadedFile,
          skancertyfikatu: uploadedCertification,
        },
      ],
    };
    fetchForm(submittedData);
  };

  if (!open) return null;

  return ReactDom.createPortal(
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="Modal">
          <div className="Modal__title">
            <div className="ModalError__closeBtnContainer">
        <div className="ModalError__closeBtnContainer__closeBtn" onClick={onClose}></div>
      </div>
            <div className="Modal__title__text">Podsumowanie zgłoszenia</div>
          </div>
          <div className="Modal__summary">
            <div className="Modal__summary__userDetails">
              <div className="summaryTitle">
                <img src="/img/person_icon.svg" alt="person icon" />
                <h2 id="UserDetails">Zgłaszający</h2>
              </div>
              <div className="Modal__summary__userDetails__info">
                <div className="Modal__summary__userDetails__info--data">
                  <p>imię i nazwisko:</p>
                  <p className="fromUser">
                    {values.imie} {values.nazwisko}
                  </p>
                </div>

                {window.innerWidth < 1366 && values.nip ? (
                  <div className="Modal__summary__userDetails__info--data">
                    <p>NIP:</p>
                    <p className="fromUser">{values.nip}</p>
                  </div>
                ) : null}

                {window.innerWidth < 1366 && values.nazwafirmy ? (
                  <div className="Modal__summary__userDetails__info--data">
                    <p>nazwa firmy:</p>
                    <p className="fromUser">{values.nazwafirmy}</p>
                  </div>
                ) : null}

                <div className="Modal__summary__userDetails__info--data">
                  <p>adres:</p>
                  <p className="fromUser">
                    {values.nadawcaulica} {values.nadawcanrdomu}{" "}
                    {values.nadawcanrlokalu}
                  </p>
                </div>
                <div className="Modal__summary__userDetails__info--data">
                  <p>miejscowość:</p>
                  <p className="fromUser">
                    {values.nadawcakodpocztowy} {values.nadawcamiasto}
                  </p>
                </div>
                <div className="Modal__summary__userDetails__info--data">
                  <p>telefon:</p>
                  <p className="fromUser">{values.tel}</p>
                </div>
                <div className="Modal__summary__userDetails__info--data">
                  <p>e-mail:</p>
                  <p className="fromUser">{values.email}</p>
                </div>

                {window.innerWidth >= 1366 && values.nip ? (
                  <div className="Modal__summary__userDetails__info--data">
                    <p>NIP:</p>
                    <p className="fromUser">{values.nip}</p>
                  </div>
                ) : null}
                {window.innerWidth >= 1366 && values.nazwafirmy ? (
                  <div className="Modal__summary__userDetails__info--data">
                    <p>nazwa firmy:</p>
                    <p className="fromUser">{values.nazwafirmy}</p>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="Modal__summary__toolsSummary">
              <div className="summaryTitle">
                <img src="/img/drill_icon.svg" alt="drill icon" />
                <h2>Narzędzie</h2>
              </div>
              <div className="Modal__summary__toolsSummary__container">
                <div className="Modal__summary__toolsSummary__container__info--data">
                  <p>model:</p>
                  <p className="fromUser">{values.narzedzia[0].modeltowaru}</p>
                </div>
                {values.narzedzia[0].datazakupu ? (
                  <div className="Modal__summary__toolsSummary__container__info--data">
                    <p>data zakupu:</p>
                    <p className="fromUser">{values.narzedzia[0].datazakupu}</p>
                  </div>
                ) : null}

                {values.narzedzia[0].nrseryjny ? (
                  <div className="Modal__summary__toolsSummary__container__info--data">
                    <p>nr seryjny:</p>
                    <p className="fromUser">{values.narzedzia[0].nrseryjny}</p>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="Modal__summary__deliverySummary">
              <div className="summaryTitle">
                <img src="/img/box_icon.svg" alt="person icon" />
                <h2>Odbiór przesyłki</h2>
              </div>
              <div className="Modal__summary__deliverySummary__container">
                {values.typprzesylki ? (
                  <div className="Modal__summary__deliverySummary__container__data">
                    <p>przewoźnik:</p>
                    <p className="fromUser">
                      {watch("typprzesylki") === ("DPD" || "DHL")
                        ? values.typprzesylki
                        : values.typprzesylki === "Inpost"
                        ? "Inpost"
                        : values.typprzesylki === "dpd ps"
                        ? "DPD"
                        : "DHL"}
                    </p>
                  </div>
                ) : null}

                {values.dzien_odbioru ? (
                  <div className="Modal__summary__deliverySummary__container__data">
                    <p>data odbioru:</p>
                    <p className="fromUser">{values.dzien_odbioru}</p>
                  </div>
                ) : null}

                <div className="Modal__summary__deliverySummary__container__data">
                  <p>rozmiar paczki:</p>
                  <p className="fromUser">{values.rozmiar_paczki}</p>
                </div>

                {values.godzina_odbioru ? (
                  <div className="Modal__summary__deliverySummary__container__data">
                    <p>godzina odbioru:</p>
                    <p className="fromUser">{values.godzina_odbioru}</p>
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="confirmationButtonBox">
            <button
              className="confirmationButtonBox__button"
              type="submit"
              onClick={fetchForm}
            >
              Zatwierdź
            </button>
          </div>
        </div>
        <div className="Modal__overlay"></div>

        {ModalThankYouIsOpen && (
          <ModalThankYou openThankYou={ModalThankYouIsOpen} />
        )}
        {isError && <ModalError isError={isError} onClose={() => setIsError(false)} />}
        {isLoading && <Spinner isLoading={isLoading} />}
        {/* <Spinner /> */}

      </form>
    </>,
    document.getElementById("portal")
  );
};
