import './DatePicker.scss';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import BaseDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { addDays, getDay } from 'date-fns';

import pl from 'date-fns/locale/pl';
registerLocale('pl', pl);

export const DatePicker = ({
  control,
  placeholder,
  name,
  minDate,
  maxDate,
  filterDate,
  excludeDates,
  fetch,
  value
}) => {
  const [startDate, setStartDate] = useState(new Date());

  const { register, watch } = useFormContext();




  return (
    <div className="PurchaseDate">
      <span className="PurchaseDate--icon">
        <img src="/img/calendar_icon.svg" alt="calendar icon"></img>
      </span>

      <Controller
        name={name}
        control={control}
        defaultValue={new Date()}
        {...register(name)}
        render={({ field: { onChange } }) => {
          return (
            <BaseDatePicker
              className="PurchaseDate__DatePicker long"
              selected={startDate}
              disabledKeyboardNavigation
              onChange={
                (data) => {
                onChange(dayjs(data).format('YYYY-MM-DD'));
                setStartDate(data);
                fetch();
              }
            }
              filterDate={filterDate}
              placeholderText={placeholder}
              excludeDates={excludeDates}
              minDate={minDate}
              maxDate={maxDate}
              value={value}
              locale="pl"
              dateFormat="dd.MM.yyyy"

            />
          );
        }}
      />
    </div>
  );
};
