import "./ComplaintType.scss";
import { Section } from "../Section/Section";
import { useFormContext } from "react-hook-form";
import { useRef, useEffect } from "react";

export const ComplaintType = () => {
  const {
    register,
    formState: { errors },
    watch,
    resetField,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();

  const values = getValues();

  return (
    <Section
      title={
        <>
          <img src="/img/squares_icon.svg" alt="squares icon" />
          <h2 id="ComplaintType">Typ reklamacji</h2>
        </>
      }
      description="Wskaż czy Twoje narzędzie znajduje się okresie gwarancyjnym czy jest już po jego zakończeniu. Weryfikacja usterki jest bezpłatna tylko dla narzędzi objętych gwarancją."
    >
      {!!errors.narzedzia?.length && errors.narzedzia[0].typzgloszenia && (
        <p className="error">Wybierz rodzaj usługi</p>
      )}
      <div className="complaintType__selection">
        <label>
          <div
            className={
              watch("narzedzia[0].typzgloszenia") === "gwarancja"
                ? "clicked"
                : "complaintType__selection--option"
            }
            onClick={() => {
              resetField("narzedzia[0].akceptkosztownogw");
              if (watch("imie") === '') {
                setError(      
                  "imie",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nazwisko") === '') {
                setError(      
                  "nazwisko",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcaulica") === '') {
                setError(      
                  "nadawcaulica",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcanrdomu") === '') {
                setError(
                  "nadawcanrdomu",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcakodpocztowy").length < 6) {
                setError(
                  "nadawcakodpocztowy",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcamiasto") === '') {
                setError(
                  "nadawcamiasto",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("tel").length < 11) {
                setError(      
                  "tel",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("email") === '') {
                setError(      
                  "email",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
            }}
          >
            <div className="shield"></div>
            <span>gwarancyjna</span>
          </div>
          <input
            {...register("narzedzia[0].typzgloszenia", { required: true })}
            type="radio"
            value="gwarancja"
            name="narzedzia[0].typzgloszenia"
          />
        </label>

        <label>
          <div
            className={
              watch("narzedzia[0].typzgloszenia") === "pogwarancyjna"
                ? "clicked"
                : "complaintType__selection--option"
            }
            onClick={() => {
              resetField("narzedzia[0].akceptkosztownogw");
              if (watch("imie") === '') {
                setError(      
                  "imie",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                )
              } else (clearErrors("imie"))
              if (watch("nazwisko") === '') {
                setError(      
                  "nazwisko",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcaulica") === '') {
                setError(      
                  "nadawcaulica",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("nadawcanrdomu") === '') {
                setError(
                  "nadawcanrdomu",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcakodpocztowy") === '') {
                setError(
                  "nadawcakodpocztowy",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("nadawcamiasto") === '') {
                setError(
                  "nadawcamiasto",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              }
              if (watch("tel") === '') {
                setError(      
                  "tel",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
              if (watch("email") === '') {
                setError(      
                  "email",
                  { type: "focus", message: "Pole obowiązkowe" },
                  { shouldFocus: true }
                );
              } 
            }}
          >
            <div className="wallet"></div>
            <span>pogwarancyjna</span>
          </div>
          <input
            {...register("narzedzia[0].typzgloszenia", { required: true })}
            type="radio"
            value="pogwarancyjna"
            name="narzedzia[0].typzgloszenia"
          />
        </label>
      </div>

      {watch("narzedzia[0].typzgloszenia") === "gwarancja" ? (
        <div className="complaintType__checkbox">
          <label className="control control-checkbox">
            Oświadczam, że zapoznałem się z warunkami gwarancji producenta, a
            wysyłane urządzenia znajdują się w okresie gwarancyjnym. W przypadku
            gdy urządzenie jest poza okresem gwarancyjnym, zobowiązuję się do
            pokrycia kosztów transportu oraz ekspertyzy urządzenia. Aktualny
            cennik usług pogwarancyjnych znajdą Państwo na naszej stronie
            www.erpatech.pl
            <input
              {...register("narzedzia[0].akceptkosztownogw", {
                required: true,
              })}
              type="checkbox"
              name="narzedzia[0].akceptkosztownogw"
              value="1"
            ></input>
            <div className="control_indicator"></div>
            {!!errors.narzedzia?.length &&
              errors.narzedzia[0].akceptkosztownogw && (
                <p className="error">Zaznacz zgodę</p>
              )}
          </label>
        </div>
      ) : watch("narzedzia[0].typzgloszenia") === "pogwarancyjna" ? (
        <div className="complaintType__checkbox">
          <label className="control control-checkbox">
            W przypadku rezygnacji z naprawy akceptuję koszty związane z
            wykonaniem ekspertyzy. Aktualny cennik usług pogwarancyjnych znajdą
            Państwo na naszej stronie www.erpatech.pl
            <input
              {...register("narzedzia[0].akceptkosztownogw", {
                required: true,
              })}
              type="checkbox"
              name="narzedzia[0].akceptkosztownogw"
              value="1"
            ></input>
            <div className="control_indicator"></div>
            {!!errors.narzedzia?.length &&
              errors.narzedzia[0].akceptkosztownogw && (
                <p className="error">Zaznacz zgodę</p>
              )}
          </label>
        </div>
      ) : null}
    </Section>
  );
};
