import "./PackageDetails.scss";
import React, { useState } from "react";
import { DeliveryDate, PackageSize } from "./components";
import { Section } from "../Section";
import { useFormContext } from "react-hook-form";

export const PackageDetails = () => {
  const {
    register,
    formState: { errors },
    watch,
    control,
    resetField,
  } = useFormContext();
  const [deliveryType, setDeliveryType] = useState("");
  const isCourier = deliveryType === "courier";
  const isParcelLocker = deliveryType === "parcelLocker";

  const handleDelivetyType = (type) => {
    setDeliveryType(type);
  };

  return (
    <Section
      title={
        <>
          <img src="/img/box_icon.svg" alt="box icon" />
          <h2 id="PackageDetails">Odbiór przesyłki</h2>
        </>
      }
      description="
      Wybierz, czy chcesz, aby kurier odebrał przesyłkę z wskazanego adresu, czy wolisz ją nadać osobiście w paczkomacie lub punkcie nadań. Aby wyświetlić dospępne godziny odbioru paczki przez kuriera, wpisz adres, oraz wybierz datę nadania."
    >
      <p>Wybierz sposób nadania paczki:</p>

      <div className="PackageDetails">
        <div
          onClick={() => {
            handleDelivetyType("courier");
            resetField("typprzesylki");
            resetField("rozmiar_paczki");
          }}
          className={
            isCourier
              ? "clickedDeliveryOption"
              : isParcelLocker
              ? "gray"
              : "PackageDetails__option"
          }
        >
          <img src="/img/car_icon.svg" alt="car icon" />
          <div className="PackageDetails__option--text">wysyłam kurierem</div>
        </div>
        <div
          onClick={() => {
            handleDelivetyType("parcelLocker");
            resetField("typprzesylki");
            resetField("dzien_odbioru");
            resetField("godzina_odbioru");
            resetField("rozmiar_paczki");
          }}
          className={
            deliveryType === "parcelLocker"
              ? "clickedDeliveryOption"
              : isCourier
              ? "gray"
              : "PackageDetails__option"
          }
        >
          <img src="/img/paczkomat_icon.svg" alt="pointer icon" />
          <div className="PackageDetails__option--text">
            paczkomat lub punkt nadania
          </div>
        </div>
      </div>

      <div className="ChoosingCompany">
        {isCourier || isParcelLocker ? (<p>Wybierz przewoźnika:</p>) :null}
        {isCourier && (
          <div className="CompanyList">
            {errors?.typprzesylki && <p className="error">Wybierz firmę</p>}
            <div className="CompanyList__options">
              <label>
                <div
                  className={
                    watch("typprzesylki") === "DPD"
                      ? "clickedOption"
                      : "CompanyList__options__option"
                  }
                  onClick={() => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
                  }}
                >
                  <img src="img/logo_DPDKurier.png" alt="logo DPD" />
                </div>
                <input
                  {...register("typprzesylki", {
                    required: true,
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="DPD"
                />
              </label>
              <label>
                <div
                  className={
                    watch("typprzesylki") === "DHL"
                      ? "clickedOption"
                      : "CompanyList__options__option"
                  }
                  onClick={() => {
                    resetField("godzina_odbioru");
                    resetField("dzien_odbioru");
                  }}
                >
                  <img src="img/logo_DHLKurier.png" alt="logo DHL" />
                </div>
                <input
                  {...register("typprzesylki", {
                    required: true,
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="DHL"
                />
              </label>
            </div>

            <div className="ChoosingDeliveryDateAndTime">
              {watch("typprzesylki") === "DHL" && !isParcelLocker && (
                <DeliveryDate />
              )}
              {watch("typprzesylki") === "DPD" && !isParcelLocker && (
                <DeliveryDate />
              )}
            </div>
          </div>
        )}
        {isParcelLocker && (
          <div className="CompanyList">
            {errors?.typprzesylki && <p className="error">Wybierz firmę</p>}

            <div className="CompanyList__options">
              <label>
                <input
                  {...register("typprzesylki", {
                    required: true,
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="Inpost"
                />

                <div
                  className={
                    watch("typprzesylki") === "Inpost"
                      ? "clickedOption"
                      : "CompanyList__options__option"
                  }
                >
                  <img src="img/logo_InPost-Paczkomat.png" alt="logo Inpost" />
                </div>
              </label>
              <label>
                <input
                  {...register("typprzesylki", {
                    required: true,
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="dpd ps"
                />
                <div
                  className={
                    watch("typprzesylki") === "dpd ps"
                      ? "clickedOption"
                      : "CompanyList__options__option"
                  }
                >
                  <img src="img/logo_DPD pickup.png" alt="logo DPD" />
                </div>
              </label>
              <label>
                <input
                  {...register("typprzesylki", {
                    required: true,
                  })}
                  type="radio"
                  name="typprzesylki"
                  value="dhl pop"
                />
                <div
                  className={
                    watch("typprzesylki") === "dhl pop"
                      ? "clickedOption"
                      : "CompanyList__options__option"
                  }
                >
                  <img src="img/logo_DHL_paczkomat.png" alt="logo DHL" />
                </div>
              </label>
            </div>
          </div>
        )}
      </div>
      {watch("typprzesylki") === "Inpost" && deliveryType === "parcelLocker" ? (
        <div className="massage">
          Po potwierdzeniu zgłoszenia, kod do paczkomatu zostanie wysłany na
          wskazany adres e-mail. Aby nadać przesyłkę, udaj się do paczkomatu i
          wprowadź na ekranie otrzymany kod. Otworzy się skrytka, umieść w niej
          paczkę i zamknij drzwiczki.
        </div>
      ) : null}
      {watch("typprzesylki") === "dhl pop" &&
      deliveryType === "parcelLocker" ? (
        <div className="massage">
          Wydrukuj etykietę i zanieś paczkę do wybranego punktu obsługi paczek
          lub DHL BOX.
        </div>
      ) : null}

      {watch("typprzesylki") === "dpd ps" && deliveryType === "parcelLocker" ? (
        <div className="massage">
          Wydrukuj etykietę i zanieś paczkę do wybranego punktu obsługi paczek
        </div>
      ) : null}

      <p>Kategoria przesyłki:</p>

      {errors.rozmiar_paczki && <p className="error">Wybierz rozmiar paczki</p>}

      {deliveryType === "parcelLocker" ? (
        <div className="packageSize">
          <PackageSize
            size="mała (S) 64 x 38 x 8 cm"
            icon={<img src="img/small_box_icon.svg" alt="small box icon" />}
            packageDescription="mała (S)"
            range="64 x 38 x 8 cm"
            isActive={watch("rozmiar_paczki") === "mała (S) 64 x 38 x 8 cm"}
            register={register}
          />
          <PackageSize
            size="średnia (M) 64 x 38 x 19 cm"
            icon={<img src="img/medium_box_icon.svg" alt="medium box icon" />}
            packageDescription="średnia (M)"
            range="64 x 38 x 19 cm"
            isActive={watch("rozmiar_paczki") === "średnia (M) 64 x 38 x 19 cm"}
            register={register}
          />
          <PackageSize
            size="duża (L) 64 x 38 x 41 cm"
            icon={<img src="img/big_box_icon.svg" alt="big box icon" />}
            packageDescription="duża (L)"
            range="64 x 38 x 41 cm"
            isActive={watch("rozmiar_paczki") === "duża (L) 64 x 38 x 41 cm"}
            register={register}
          />
          <PackageSize
            size="wielka"
            icon={<img src="img/huge_box_icon.svg" alt="huge box icon" />}
            packageDescription="wielka"
            range="-"
            isDisabled={isParcelLocker}
            isActive={watch("rozmiar_paczki") === "wielka"}
            register={register}
          />
          <PackageSize
            size="paleta"
            icon={<img src="img/pallet_icon.svg" alt="pallete icon" />}
            packageDescription="paleta"
            range="-"
            isDisabled={isParcelLocker}
            isActive={watch("rozmiar_paczki") === "paleta"}
            register={register}
          />
        </div>
      ) : (
        <div className="packageSize">
          <PackageSize
            size="mała (do 5 kg)"
            icon={<img src="img/small_box_icon.svg" alt="small box icon" />}
            packageDescription="mała"
            range="do 5 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "mała (do 5 kg)"}
            register={register}
          />
          <PackageSize
            size="średnia (do 10 kg)"
            icon={<img src="img/medium_box_icon.svg" alt="medium box icon" />}
            packageDescription="średnia"
            range="do 10 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "średnia (do 10 kg)"}
            register={register}
          />
          <PackageSize
            size="duża (do 20 kg)"
            icon={<img src="img/big_box_icon.svg" alt="big box icon" />}
            packageDescription="duża"
            range="do 20 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "duża (do 20 kg)"}
            register={register}
          />
          <PackageSize
            size="wielka (do 35 kg)"
            icon={<img src="img/huge_box_icon.svg" alt="huge box icon" />}
            packageDescription="wielka"
            range="do 35 kg"
            isDisabled={!isCourier && !isParcelLocker}
            isActive={watch("rozmiar_paczki") === "wielka (do 35 kg)"}
            register={register}
          />
          <PackageSize
            size="paleta"
            icon={<img src="img/pallet_icon.svg" alt="pallete icon" />}
            packageDescription="paleta"
            range=" "
            isActive={watch("rozmiar_paczki") === "paleta"}
            register={register}
          />
        </div>
      )}
    </Section>
  );
};
