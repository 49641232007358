import React from 'react';
import './ModalThankYou.scss';
import ReactDom from 'react-dom';

export const ModalThankYou = ({ openThankYou }) => {
  if (!openThankYou) return null;
  return ReactDom.createPortal(
    <div className="ModalThankYou">
      <img src="/img/ok_icon.png" />
      <p className="ModalThankYou__Title">Dziękujemy!</p>
      <p className="ModalThankYou__Description">
        Twoje zgłoszenie zostało przyjęte
      </p>
      
      <div className="CloseButtonBox">
       <a href="/"> <button className="CloseButtonBox__button">Zamknij</button></a>
      </div>
    </div>,
    document.getElementById('portalThankYou')
  );
};
