import React, { useState } from "react";
import "./ClipboardCopy.scss"
import { useFormContext } from "react-hook-form";



export const ClipboardCopy = ({ copyText }) => {
    const { getValues, watch } = useFormContext();
    const values = getValues();

  const [isCopied, setIsCopied] = useState(false);


  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } 
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
    .then(() => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 5000);
    })
    .catch((err) => {
        console.log(err);
    });
  }



  return (
    <div>
      <input  className="Clipboard__input" type="text" readOnly value={copyText}>
      </input>

      <button  onClick={handleCopyClick}>
        <span className="Clipboard__button">{isCopied ? "Skopiowane" : "Kopiuj dane"}</span>
      </button>
    </div>
  );
};
