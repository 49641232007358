import { DatePicker } from '../../../../../DatePicker';
import './DeliveryDate.scss';
import { useFormContext } from 'react-hook-form';
import { addDays, getDay } from 'date-fns';
import { useState } from 'react';
import { useCallback, useEffect } from 'react';
import { db } from '../../../../../settings';

export const DeliveryDate = () => {
  const [deliveryHours, setDeliveryHours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { control, watch, setError, register, getValues,  formState: { errors, isValid } } = useFormContext({ shouldUseNativeValidation: true });

  const values = getValues();

  //sposób zapiywania dat, które mają zostać wyłczone z kalendarza odbioru paczek: new Date(2023, 5, 2),
  // styczen - 0; luty - 1, marzec - 2, kwiecien - 3, maj - 4, czerwiec - 5,
  // lipiec - 6, sierpień - 7, wrzesień - 8, październik - 9, listopad - 10, grudzień -11
  console.log('token', db.token, 'url', db.url);

  const excludedDates = [
    new Date(2023, 0, 1), // Nowy Rok
    new Date(2023, 0, 6), // Święto Trzech Króli
    new Date(2023, 3, 9), // Wielkanoc
    new Date(2023, 3, 10), // Poniedziałek Wielkanocny
    new Date(2023, 4, 1), // Święto Pracy
    new Date(2023, 4, 3), // Święto Konstytucji 3 Maja
    new Date(2023, 4, 28), // Zielone Świątki
    new Date(2023, 5, 8), // Boże Ciało
    new Date(2023, 7, 15), // Wniebowzięcie Najświętszej Maryi Panny
    new Date(2023, 10, 1), // Wszystkich Świętych
    new Date(2023, 10, 11), // Święto Niepodległości
    new Date(2023, 11, 25), // Pierwszy dzień Bożego Narodzenia
    new Date(2023, 11, 26), // Drugi dzień Bożego Narodzenia
    new Date(2024, 0, 1), // Nowy Rok
    new Date(2024, 0, 6), // Święto Trzech Króli
    new Date(2024, 2, 31), // Wielkanoc
    new Date(2024, 3, 1), // Poniedziałek Wielkanocny
    new Date(2024, 4, 1), // Święto Pracy
    new Date(2024, 4, 3), // Święto Konstytucji 3 Maja
    new Date(2024, 4, 19), // Zielone Świątki
    new Date(2024, 4, 30), // Boże Ciało
    new Date(2024, 7, 15), // Wniebowzięcie Najświętszej Maryi Panny
    new Date(2024, 10, 1), // Wszystkich Świętych
    new Date(2024, 10, 11), // Święto Niepodległości
    new Date(2024, 11, 25), // Pierwszy dzień Bożego Narodzenia
    new Date(2024, 11, 26), // Drugi dzień Bożego Narodzenia
    new Date(2025, 0, 1), // Nowy Rok
    new Date(2025, 0, 6), // Święto Trzech Króli
  

  ];

  const isWeekday = (date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  useEffect(() => {
    fetchDeliveryHours();
  }, [
    watch('dzien_odbioru'),
    watch('nadawcakodpocztowy'),
    watch('nadawcamiasto'),
    watch('nadawcaulica'),
    watch('nadawcanrdomu'),
    watch('typprzesylki'),
  ]);

  const fetchDeliveryHours = useCallback(async () => {
    try {
      setIsLoading(true);
      console.log('token', db.token, 'url', db.url);

      const response = await fetch(
        `${db.url}/api/get_time?date=${watch(
          'dzien_odbioru'
        )}&post_code=${watch('nadawcakodpocztowy')}&city=${watch(
          'nadawcamiasto'
        )}&street=${watch('nadawcaulica')}&houseNumber=${watch(
          'nadawcanrdomu'
        )}&aprtmentNumber=${watch('nadawcanrlokalu')}&kurier=${watch(
          'typprzesylki'
        )}`,
        {
          method: 'get',
          headers: {
            Authorization: db.token,
            'Content-Type': 'application/json',
          },
        }
      );
      const data = await response.json();
      const dataHours = data.response;
      if (
        watch('dzien_odbioru') &&
        watch('nadawcakodpocztowy') &&
        watch('nadawcamiasto') &&
        watch('nadawcaulica') &&
        watch('nadawcanrdomu') &&
        watch('typprzesylki')
      ) {
        setDeliveryHours(dataHours);
        setIsLoading(false);
      }
    } catch (error) {
      console.log('ERRRRROR', error);
    }
  }, [
    watch('dzien_odbioru'),
    watch('nadawcakodpocztowy'),
    watch('nadawcamiasto'),
    watch('nadawcaulica'),
    watch('nadawcanrdomu'),
    watch('typprzesylki'),
  ]);

  return (
    <div className="SelectionDataAndTime">
      <div className="SelectionDataAndTime__date">
        <DatePicker
          control={control}
          placeholder="Data odbioru"
          name="dzien_odbioru"
          minDate={addDays(new Date(), 1)}
          maxDate={addDays(new Date(), 4)}
          excludeDates={excludedDates}
          filterDate={isWeekday}
          register={register}
          fetch={fetchDeliveryHours}
          value={values.dzien_odbioru}
        />
      </div>
      <div className="SelectionDataAndTime__time">
        <img
          className="SelectionDataAndTime__time--img"
          src="/img/clock_icon.svg"
          alt="clock icon"
        />

        {errors.godzina_odbioru?.message && <p className="error">{errors.godzina_odbioru?.message}</p>
 }
        <div 
        className="SelectionDataAndTime__time__customSelect"
        
      >
          <select
            required
            selected
            {...register('godzina_odbioru', {
              required: {
                value: "Wybierz godzinę"
              }
            })}
            
            className="SelectionDataAndTime__time__customSelect__select"
            name="godzina_odbioru"
          >
            <option
              className="SelectionDataAndTime__time__customSelect__select--option"
              value=""
              disabled
            >
              Wybierz godziny odbioru
            </option>

            {isLoading && <option className="SelectionDataAndTime__time__customSelect__select--option"
              value=""
              disabled>Sprawdzam godziny... Aby wyświetlić godziny, wpisz adres oraz wybierz datę.</option>}

            {!isLoading &&
              deliveryHours.map((hour) => (               
                <>               
                  <option
                    className="SelectionDataAndTime__time__customSelect__select--option"
                    value={hour}
                    disabled={hour === "brak - brak"}
                    key={() => Math.random }
                  >
                    {hour === "brak - brak" ? "Kurier nie obsługuje wybranego kodu pocztowego" : hour}          
                  </option>
                </>
              ))}
          </select>
        </div>
      </div>
    </div>
  );
};
