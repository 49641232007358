import React from "react";
import ReactDom from "react-dom";
import "./ModalError.scss";
import { useFormContext } from "react-hook-form";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ClipboardCopy } from "./ClipboardCopy";
import { StepNumber } from "./components/StepNumber";

export const ModalError = ({ isError, onClose }) => {
  const { getValues, watch } = useFormContext();
  const values = getValues();

  if (!isError) return null;
  return ReactDom.createPortal(
    <div className="ModalError">
      {/* <div className="ModalError__closeBtnContainer">
        <div
          className="ModalError__closeBtnContainer__closeBtn"
          onClick={onClose}
        ></div>
      </div> */}

      <div className="ModalError__content">
        <div className="ModalError__content__header">
          <h1 className="ModalError__content__header__title">Wystapił błąd</h1>
          <p className="ModalError__content__header__description">Co teraz? </p>
        </div>
        <div className="ModalError__content__body">
          <div className="ModalError__content__body__step">
            <StepNumber
              number="1"
              titleText="Kliknij przycisk „kopiuj dane”"
              descriptionText="Wszystkie informacje wpisane w formularzu zostaną skopiowane do schowka"
              linename={"step__content__noline"}
            />

            <StepNumber
              number="2"
              titleText="Utwórz nowego e-maila"
              descriptionText="Otwórz swoją skrzynkę mailową i utwórz nową wiadomość do"
              mail="serwis@erpatech.pl"
              linename={"step__content__line"}
            />

            <StepNumber
              number="3"
              titleText="W treści maila kliknij „wklej”"
              descriptionText="Wklej skopiowane dane i wyślij wiadomość. Na ich podstawie przyjmiemy zgłoszenie"
              linename={"step__content__line"}
            />
          </div>
        </div>

        <ClipboardCopy
          copyText={
            "Zgłaszający" +
            "\n" +
            "Imię i nazwisko:" +
            values.imie +
            " " +
            values.nazwisko +
            "\n" +
            "Nazwa firmy i NIP:" +
            values.nazwafirmy +
            values.nip +
            "\n" +
            "Adres: " +
            values.nadawcaulica +
            " " +
            values.nadawcanrdomu +
            "/" +
            values.nadawcanrlokalu +
            " " +
            values.nadawcakodpocztowy +
            " " +
            values.nadawcamiasto +
            "\n" +
            "Telefon:" +
            values.tel +
            "\n" +
            "Email:" +
            values.email +
            "\n" +
            "Narzędzie" +
            "\n" +
            "Typ reklamacji:" +
            values.narzedzia[0].typzgloszenia +
            "\n" +
            "Model:" +
            values.narzedzia[0].modeltowaru +
            "\n" +
            "Nr seryjny:" +
            values.narzedzia[0].nrseryjny +
            "\n" +
            "Data zakupu:" +
            values.narzedzia[0].datazakupu +
            "\n" +
            "Akceptacja kosztów" +
            values.narzedzia[0].akceptkosztownogw +
            "\n" +
            "Opis usterki:" +
            values.narzedzia[0].opisusterki +
            "\n" +
            "Odbiór przesyłki" +
            "\n" +
            "Przewoźnik:" +
            values.typprzesylki +
            "\n" +
            "Rozmiar paczki:" +
            values.rozmiar_paczki +
            "\n" +
            "Dzień odbioru:" +
            values.dzien_odbioru +
            "\n" +
            "Godzina odbioru:" +
            values.godzina_odbioru
          }
        />

        <div className="ModalError__content__description">
          <p>Potrzebujesz dodatkowej pomocy?</p>{" "}
          <p>
            Skontaktuj się z nami dzwoniąc na numer{" "}
            <a
              className="ModalError__links--contactBox"
              href="tel:+48223001091"
            >
              tel: (22) 431-05-05
            </a>{" "}
            lub mailowo. Pracujemy od poniedziałku do piątku w godzinach 8-16.
          </p>
        </div>
      </div>
    </div>,
    document.getElementById("portalError")
  );
};
